/* globals FormValidator */

'use strict';

var EmailValidator = function (form_id, email_input_id) {
    this.form = document.getElementById(form_id);
    this.email_input = document.getElementById(email_input_id);

    this.init();
};

EmailValidator.prototype = {
    init: function () {
        var self = this;
        this.form.onsubmit = function() {
            var valid =  self.validateEmail();
            if (valid) {
                return true;
            } else {
                FormValidator.prototype.setGroupClassOnElement(self.email_input, "has-error");
                FormValidator.prototype.setHelpMessageOnElement(self.email_input, "Invalid Email");
                self.email_input.focused = true;
                return false;
            }
        };
    },

    validateEmail: function () {
        var email = this.email_input.value;
        //Taken from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
        var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        return re.test(email);
    }
};
